import { Button, ButtonProps } from '@mui/material';
import { CrispService, RouteLink } from '@scorenco/core';
import type { marked } from 'marked';

import { RendererFn } from '../type';

import { link } from './link';

const MARKDOWN_TO_BUTTON_PROPS: Record<string, ButtonProps> = {
  principal: {
    variant: 'contained',
    color: 'secondary',
  },
  secondaire: {
    variant: 'contained',
    color: 'primary',
  },
};

export const button: RendererFn<
  marked.Tokens.Link & { buttonType: string; message?: string }
> = ({ buttonType, ...linkToken }, utils) => {
  const buttonProps = MARKDOWN_TO_BUTTON_PROPS[buttonType] || {};

  if (buttonType === 'lien') {
    return link(linkToken, utils);
  }

  if (linkToken.href.startsWith('#crisp')) {
    return (
      <Button
        onClick={() => {
          CrispService.show();

          if (linkToken.message) {
            CrispService.sendMessageToUser(linkToken.message);
          }
        }}
        key={utils.key}
        {...buttonProps}
      >
        {utils.parse(linkToken.tokens)}
      </Button>
    );
  }

  return (
    <RouteLink href={linkToken.href} key={utils.key}>
      <Button {...buttonProps}>{utils.parse(linkToken.tokens)}</Button>
    </RouteLink>
  );
};
