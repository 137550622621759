import { createContext, useContext } from 'react';

import {
  AuthentificationReducerState,
  useAuthentificationReducer,
} from './useAuthentificationReducer';

export const AmplifyServiceContext = createContext<
  AmplifyServiceContextProps | undefined
>(undefined);

export const useAmplifyServiceContext = (): AmplifyServiceContextProps => {
  const context = useContext(AmplifyServiceContext);
  if (!context) {
    throw new Error(
      'useAmplifyServiceContext must be inside a AmplifyServiceContext.Provider'
    );
  }
  return context;
};

export type AmplifyServiceContextProps = AuthentificationReducerState & {
  checkStatus: ReturnType<typeof useAuthentificationReducer>['checkStatus'];
  changeState: ReturnType<typeof useAuthentificationReducer>['changeState'];
  signOut: ReturnType<typeof useAuthentificationReducer>['signOut'];
  updateUserInfo: ReturnType<
    typeof useAuthentificationReducer
  >['updateUserInfo'];
};
