import { useMemo, useCallback, useEffect, useReducer } from 'react';

import { Types } from '../../types';

import { AuthentificationState, AuthentificationStatus } from './types';
import { useCurrentUserInfo } from './useCurrentUserInfo';

export type AuthentificationReducerState = {
  authState: AuthentificationState;
  status: AuthentificationStatus;
  formInput: {
    username?: string;
    email?: string;
    password?: string;
    sport?: string;
    club?: Types.Clubs_Club;
    team?: Types.Teams_Team_Detail;
    cognito_username?: string;
  };
  user?: { jwtToken: string; info: Types.Users_User };
};

enum ActionTypes {
  SignIn = 'SIGN_IN',
  SignOut = 'SIGN_OUT',
  ChangeState = 'CHANGE_STATE',
  UpdateUserInfo = 'UpdateUserInfo',
}

type Actions =
  | {
      type: ActionTypes.SignIn;
      payload: {
        user: AuthentificationReducerState['user'];
      };
    }
  | {
      type: ActionTypes.SignOut;
    }
  | {
      type: ActionTypes.ChangeState;
      payload: {
        authState?: AuthentificationState;
        formInput?: AuthentificationReducerState['formInput'];
      };
    }
  | {
      type: ActionTypes.UpdateUserInfo;
      payload: {
        userInfo?: Types.Users_User;
      };
    };

const initialState: AuthentificationReducerState = {
  authState: AuthentificationState.Initialize,
  status: AuthentificationStatus.Loading,
  formInput: {},
};

const authStateToAuthStatus = (authState: AuthentificationState) => {
  if (authState === AuthentificationState.Initialize) {
    return AuthentificationStatus.Loading;
  }

  if (authState === AuthentificationState.SignedIn) {
    return AuthentificationStatus.Authenticated;
  }

  return AuthentificationStatus.NotAuthenticated;
};

const reducer = (
  state: AuthentificationReducerState,
  action: Actions
): AuthentificationReducerState => {
  switch (action.type) {
    case ActionTypes.ChangeState: {
      const authState = action.payload.authState || state.authState;
      return {
        ...state,
        authState: action.payload.authState || state.authState,
        formInput: {
          ...state.formInput,
          ...(action.payload.formInput || {}),
        },
        status: authStateToAuthStatus(authState),
      };
    }
    case ActionTypes.SignOut: {
      return {
        authState: AuthentificationState.SignedOut,
        formInput: {},
        status: AuthentificationStatus.NotAuthenticated,
      };
    }
    case ActionTypes.SignIn: {
      return {
        authState: AuthentificationState.SignedIn,
        formInput: {},
        status: AuthentificationStatus.Authenticated,
        user: action.payload.user,
      };
    }
    case ActionTypes.UpdateUserInfo: {
      if (!state.user) {
        console.log("[ERROR] You can't update user if you are not connected");
        return state;
      }

      const userInfo = action.payload.userInfo || state.user.info;

      return {
        ...state,
        user: {
          ...state.user,
          info: userInfo,
        },
      };
    }
    default:
      throw new Error();
  }
};

export const useAuthentificationReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getCurrentUserInfo } = useCurrentUserInfo();

  const checkStatus = useCallback(async () => {
    const user = await getCurrentUserInfo();
    dispatch({ type: ActionTypes.SignIn, payload: { user } });
  }, [dispatch]);

  const updateUserInfo = useCallback(
    async (userInfo?: Types.Users_User) => {
      dispatch({ type: ActionTypes.UpdateUserInfo, payload: { userInfo } });
    },
    [dispatch]
  );

  const changeState = useCallback(
    (options: {
      authState?: AuthentificationState;
      formInput?: AuthentificationReducerState['formInput'];
    }) => {
      dispatch({
        type: ActionTypes.ChangeState,
        payload: options,
      });
    },
    [dispatch]
  );

  const signOut = useCallback(() => {
    dispatch({
      type: ActionTypes.SignOut,
    });
  }, [dispatch]);

  // Check if user is already connected
  useEffect(() => {
    const promise = async () => {
      try {
        await checkStatus();
      } catch (err) {
        console.log('signOut : ', err);
        signOut();
      }
    };
    promise();
  }, []);

  return useMemo(
    () => ({
      state,
      checkStatus,
      changeState,
      signOut,
      updateUserInfo,
    }),
    [JSON.stringify(state), checkStatus, changeState, signOut, updateUserInfo]
  );
};
