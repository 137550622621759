var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"S8-C3kpUyt0cCWGmL8HH4"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENV =
  process.env.SST_STAGE || process.env.NEXT_PUBLIC_SENTRY_ENV || 'local';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENV,
  debug: false,
  tracesSampleRate: 0.05,
  enabled: process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true',
  beforeSend(event, hint) {
    if (
      event.exception &&
      event.exception.values &&
      event.exception.values.length > 0
    ) {
      const firstException = event.exception.values[0];
      if (
        firstException.value === 'ResizeObserver loop limit exceeded' ||
        firstException.type === 'ResizeObserver loop limit exceeded'
      ) {
        return null;
      }
    }

    // filter out UnhandledRejection errors that have no information
    if (
      event !== undefined &&
      event.exception !== undefined &&
      event.exception.values !== undefined &&
      event.exception.values.length == 1
    ) {
      var e = event.exception.values[0];
      if (
        e.type === 'UnhandledRejection' &&
        e.value === 'Non-Error promise rejection captured with value: '
      ) {
        return null;
      }
    }

    return event;
  },
});
