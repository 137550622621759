import { FieldPolicy } from '@apollo/client';

const FRAGMENTS = ['competitions_event_detail'];

/**
 * Permet d'activer le cache redirect sur les queries custom que l'on a ajouté à hasura.
 *
 * Pour plus d'info : https://www.apollographql.com/docs/react/caching/advanced-topics#cache-redirects
 */
export const customSingleQueries = {
  ...FRAGMENTS.reduce<Record<string, FieldPolicy>>(
    (acc, fragmentName) => ({
      ...acc,
      [`${fragmentName}_by_id`]: {
        read: (data, { args, cache, toReference }) => {
          // Si la donnée est déjà dans le cache pour cette query, on la retourne directement
          if (data) {
            return data;
          }

          const id = args?.args?.id;

          const cachedData = cache.extract()[`competitions_event_detail:${id}`];

          // Si la donnée n'est pas dans le cache, on retourne undefined.
          // Cela entrainera un appel à la query côté serveur
          if (!cachedData) {
            return undefined;
          }

          return [
            toReference({
              __typename: 'competitions_event_detail',
              id,
            }),
          ];
        },
      },
    }),
    {}
  ),
};
