import { PropsWithChildren } from 'react';

import { css, Global } from '@emotion/react';
import { Box } from '@mui/material';

import { NavigationAppBar } from './NavigationAppBar';
import { NavigationProvider } from './NavigationContext';
import { NextNavigationServiceOptions } from './types';

export const NavigationComponent = ({
  children,
  noBurgerDrawer,
  noAppBar,
  AppBarActions,
  noBottomMargin,
}: PropsWithChildren<NextNavigationServiceOptions>) => {
  return (
    <NavigationProvider
      noBurgerDrawer={noBurgerDrawer}
      AppBarActions={AppBarActions}
    >
      <Global
        styles={css`
          :root {
            --app-bar-height: ${noAppBar ? 0 : '4rem'};
          }
        `}
      />

      <Box
        css={(theme) => css`
          background: ${theme.vars.palette.background.default};
          min-height: 100vh;
          padding-top: var(--app-bar-height);
          max-width: 100%;
          /* On ajoute en marge bottom la hauteur de la bulle Crisp
          pour éviter qu'elle ne cache du contenu */
          margin-bottom: ${noBottomMargin ? 0 : '5rem'};
        `}
      >
        {!noAppBar && <NavigationAppBar />}
        {children}
      </Box>
    </NavigationProvider>
  );
};
