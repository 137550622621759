import { useState, useLayoutEffect, useEffect } from 'react';

// import { unstable_useEnhancedEffect } from '@mui/material';

const useEnhancedEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export const useIsSsr = () => {
  const [mountedState, setMountedState] = useState(false);

  useEnhancedEffect(() => {
    setMountedState(true);
  }, []);

  return !mountedState;
};
